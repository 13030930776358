/**
 * @module CategoryBasedSearch
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLifegroupData, setCategory, setMeetOnline } from '../../actions';
import {
  callSegmentTrack,
  getExperimentalCookieValue,
  getUser,
} from '../../helper';
import { ACTIONS, EVENTS } from '../../types';
import { CategoryButtons } from './CategoryButtons';
import { CategoryStep } from './CategoryStep';
import { CATEGORIES_DATA } from './variables';
import './CategoryBasedSearch.scss';

/**
 * Represents a view with step-by-step card-based group selections to display for users to select and filter.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.campusList - Array of campus data objects.
 * @param {Array} props.filteredList - Array of filtered campus data objects.
 *
 * @returns {React.ReactElement} The CategoryBasedSearch component.
 */
function CategoryBasedSearch(props) {
  const [step, setStep] = React.useState(0);

  // Convenience component-based state vars.
  const [groupTypeLocation, setGroupTypeLocation] = React.useState('inPerson');

  function callAnalytics(
    { event, properties } = { event: EVENTS.buttonAction, properties: {} },
  ) {
    const user = getUser();
    callSegmentTrack({
      event: event || EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Category Based Search',
        component_url: null,
        experimental_flag: getExperimentalCookieValue(),
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        ...properties,
      },
    });
  }

  /**
   * Handler function for card click of group type location (online or in-person groups).
   *
   * Note: In CoreActions.js, the boolean is actually a string-based true or
   * false. Further, the logic is toggle-based, so passing the opposite value
   * is actually what is needed to happen:
   *
   * In CoreActions.js:
   * - Payload code: payload: value === 'true' ? 'false' : 'true'.
   *
   * @param {object} params - The function params object.
   * @param {object} params.data - The category data object associated with the click.
   * @param {Event} params.event - The Event object associated with the click.
   */
  function handleGroupTypeLocationCardClick(params) {
    callAnalytics({
      properties: {
        label: params.data.card.title,
      },
    });
    setGroupTypeLocation(params.data.card.value);
    props.setMeetOnline(
      params.data.card.value === CATEGORIES_DATA.online.card.value
        ? 'false'
        : 'true',
    );
    props.fetchLifegroupData();
    setStep(1);
  }

  /**
   * Handler function for card click of group type (men's, women's, etc.).
   *
   * Note: If the group type is in-person, increase the step to allow for
   * location selection. Otherwise, trigger onSearchComplete() to show results.
   *
   * @param {object} params - The function params object.
   * @param {object} params.data - The category data object associated with the click.
   * @param {Event} params.event - The Event object associated with the click.
   */
  function handleGroupTypeCardClick(params) {
    callAnalytics({
      properties: {
        label: params.data.title,
      },
    });
    props.setCategory(params.data.value);
    props.fetchLifegroupData();
    if (groupTypeLocation === CATEGORIES_DATA.inPerson.card.value) {
      setStep(2);
    } else {
      props.onSearchComplete();
    }
  }

  /**
   * Handler function for card/element click of category step component.
   *
   * @param {object} params - The function params object.
   * @param {object} params.data - The category data object associated with the click.
   * @param {Event} params.event - The Event object associated with the click.
   */
  function handleCategoryStepClick(params) {
    if (step === 0) {
      handleGroupTypeLocationCardClick(params);
    } else if (step === 1) {
      handleGroupTypeCardClick(params);
    }
  }

  /**
   * Handler function for "Back" button click.
   */
  function handleBackClick() {
    callAnalytics({
      properties: {
        label: 'Back',
      },
    });
    setStep(step - 1);
  }

  /**
   * Handler function for "Next" button click.
   */
  function handleNextClick() {
    callAnalytics({
      properties: {
        label: 'Next',
      },
    });
    props.onSearchComplete();
  }

  /**
   * Handler function for "Skip" button click.
   */
  function handleSkipClick() {
    callAnalytics({
      properties: {
        label: 'Skip',
      },
    });
    if (step === 0) {
      setGroupTypeLocation(CATEGORIES_DATA.inPerson.card.value);
      setStep(step + 2);
    } else {
      props.onSearchComplete();
    }
  }

  return (
    <div
      className="category-based-search"
      data-testid="lg-category-based-search"
    >
      <div className="row">
        <div className="columns small-12 small-centered text-center">
          <CategoryStep
            campusList={props.campusList}
            filteredList={props.filteredList || ['all']}
            groupTypeLocation={groupTypeLocation}
            onClick={handleCategoryStepClick}
            stepNumber={step}
          />
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 small-centered">
          <CategoryButtons
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}
            onSkipClick={handleSkipClick}
            stepNumber={step}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ core }) {
  return {
    campusList: core.campusList,
    categories: core.categories,
    meetOnline: core.meetOnline,
  };
}

CategoryBasedSearch.propTypes = {
  setCategory: PropTypes.func,
  setMeetOnline: PropTypes.func,
};

export default connect(mapStateToProps, {
  fetchLifegroupData,
  setCategory,
  setMeetOnline,
})(CategoryBasedSearch);
