export const CATEGORIES_DATA = {
  inPerson: {
    card: {
      description: 'Gather with people in one location',
      image:
        'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-in-person.jpg',
      title: 'In-Person',
      value: 'inPerson',
    },
    categories: [
      {
        description: 'Meet up with other men',
        image:
          'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-in-person-men.jpg',
        title: 'Men',
        value: "men's",
      },
      {
        description: 'Gather with other women',
        image:
          'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-in-person-women.jpg',
        title: 'Women',
        value: "women's",
      },
      {
        description: 'Connect with other married people',
        image:
          'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-in-person-married-couples.jpg',
        title: 'Married Couples',
        value: 'married',
      },
      {
        description: 'Get to know new people',
        image:
          'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-in-person-everyone-welcome.jpg',
        title: 'Everyone Welcome',
        value: 'everyone welcome',
      },
    ],
    inlineTerm: 'in-person',
  },
  online: {
    card: {
      description: 'Meet up via video calls and chats',
      image:
        'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-online.jpg',
      title: 'Online',
      value: 'online',
    },
    categories: [
      {
        description: 'Meet up with other men',
        image:
          'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-online-men.jpg',
        title: 'Men',
        value: "men's",
      },
      {
        description: 'Gather with other women',
        image:
          'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-online-women.jpg',
        title: 'Women',
        value: "women's",
      },
      {
        description: 'Get to know new people online',
        image:
          'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-online-everyone-welcome.jpg',
        title: 'Everyone Welcome',
        value: 'everyone welcome',
      },
      {
        description: 'Connect around something you love',
        image:
          'https://cms-images.life.church/lc-site/lifegroups/lg-search-group-online-shared-interests.jpg',
        title: 'Shared Interests',
        value: 'shared interest',
      },
    ],
    inlineTerm: 'online',
  },
};
