/**
 * @module LocationSelector
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import SearchFilter from '../SearchFilter';
import CampusCheckboxContainer from '../CampusCheckboxContainer';
import AllLocationsCheckbox from '../AllLocationsCheckbox';
import FilteredCampusesContainer from '../FilteredCampusesContainer';
import LifeChurchOnlineCheckbox from '../LifeChurchOnlineCheckbox';
import '../LocationsContainer/LocationsContainer.scss';
import './CategoryBasedSearch.scss';

function LocationSelector({ campusList, filteredList }) {
  const [toggleDropdown, setToggleDropdown] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [campuses, setCampuses] = React.useState([]);
  const [filteredLocations, setFilteredLocations] = React.useState([]);
  const [isAllChecked, setIsAllChecked] = React.useState(true);
  const [isIntChecked, setIsIntChecked] = React.useState(true);

  const metros = [];
  campusList.forEach((campus) => {
    const metro = campus.metro.slice(0, -6);
    if (metro !== '' && !metros?.includes(metro)) {
      metros.push(metro);
    }
  });

  const searchCampusList = campusList.filter((campus) => {
    return campus.slug !== 'all' && campus.slug !== 'int';
  });

  // We want to count the number of locations without including 'all'
  const locationsLength = filteredList?.includes('all')
    ? campusList.length - 1
    : filteredList.length;
  const allowedCharacters = /[^a-z_ ]/gi;

  const numLocationsValue = locationsLength > 0 ? `(${locationsLength})` : '';

  function handleSearch(event) {
    const text = event.target.value.replace(allowedCharacters, '');
    setSearchText(text);
    if (text === '') {
      setFilteredLocations([]);
    } else {
      const firstRegex = `^(${text})`;
      const containsRegex = `${text}`;

      const firstMetros = metros.filter((metro) =>
        metro.match(new RegExp(firstRegex, 'i')),
      );
      const newMetroList = metros.filter((ele) => {
        return !firstMetros?.includes(ele);
      });

      const firstResults = searchCampusList
        .filter((campus) => campus.name.match(new RegExp(firstRegex, 'i')))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      const newCampusList = searchCampusList.filter((ele) => {
        return !firstResults?.includes(ele);
      });

      const containsMetros = newMetroList.filter((metro) =>
        metro.match(new RegExp(containsRegex, 'i')),
      );

      const containsResults = newCampusList
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .filter((campus) => campus.name.match(new RegExp(containsRegex, 'i')));
      setFilteredLocations([
        ...firstMetros,
        ...firstResults,
        ...containsMetros,
        ...containsResults,
      ]);
    }
  }

  React.useEffect(() => {
    function sortCampuses() {
      const map = {};
      const locationList =
        filteredLocations.length === 0 ? campusList : filteredLocations;

      const sortedList = locationList
        .filter((a) => a.state)
        .sort((a, b) => (a.metro > b.metro ? 1 : -1));

      sortedList.forEach((campus) => {
        if (map[campus.state]) {
          if (!map[campus.state][campus.metro]) {
            map[campus.state][campus.metro] = [campus];
          } else {
            map[campus.state][campus.metro].push(campus);
          }
        } else {
          map[campus.state] = { [campus.metro]: [campus] };
        }
      });
      setCampuses(map);
    }
    sortCampuses();
  }, [campusList, filteredLocations]);

  React.useEffect(() => {
    if (!filteredList?.includes('all')) {
      setIsAllChecked(false);
    } else {
      setIsAllChecked(true);
    }
  }, [filteredList]);

  return (
    <div
      className={`dropdown-outer-container dropdown-outer-${
        toggleDropdown ? 'open' : 'closed'
      } text-left`}
    >
      <SearchFilter handleSearch={handleSearch} searchText={searchText} />
      {filteredLocations.length === 0 ? (
        <>
          {searchText !== '' ? (
            <>
              <p className="no-result font-weight-semibold">0 Results Found</p>
              <p className="no-result-description">
                Didn't find what you are looking for?
              </p>
              <p className="no-result-description">
                Try another keyword or see all the campuses near you
              </p>
            </>
          ) : (
            <div className="campuses-container">
              <AllLocationsCheckbox
                isChecked={isAllChecked}
                key="all-locations"
                name="all-locations"
                setIsChecked={setIsAllChecked}
              />
              <LifeChurchOnlineCheckbox
                isAllChecked={isAllChecked}
                isChecked={isIntChecked}
                key="lco"
                setIsAllChecked={setIsAllChecked}
                setIsChecked={setIsIntChecked}
              />
              {Object.keys(campuses).length
                ? Object.keys(campuses)
                    .sort() // NOSONAR
                    .map((state) => (
                      <CampusCheckboxContainer
                        campuses={campuses[state]}
                        isAllChecked={isAllChecked}
                        key={state}
                        setIsAllChecked={setIsAllChecked}
                        state={state}
                      />
                    ))
                : null}
            </div>
          )}
        </>
      ) : (
        <div className="campuses-container">
          <FilteredCampusesContainer
            isAllChecked={isAllChecked}
            locations={filteredLocations}
            setIsAllChecked={setIsAllChecked}
          />
        </div>
      )}
    </div>
  );
}

export { LocationSelector };
