// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import ScrollToTop from 'react-scroll-up';
import MediaQuery from 'react-responsive';
import {
  callSegmentPage,
  callSegmentTrack,
  getExperimentalCookieValue,
  getUser,
} from '../../helper';
import {
  ACTIONS,
  APP_NAME,
  EVENTS,
  EXPERIMENTS,
  EXPERIMENT_URL_SEARCH_OVERRIDES,
} from '../../types';
import CategoryBasedSearch from '../CategoryBasedSearch';
import SearchContainer from '../SearchContainer';
import ListContainer from '../ListContainer';
import ScrollToTopImage from '../../images/scroll_to_top.svg';

function SearchPage(props) {
  /**
   * Style object is optional, overridden here with values to ensure visibility
   * and more proper placement.
   *
   * For more information, see: https://www.npmjs.com/package/react-scroll-up}.
   */
  const scrollToTopStyle = {
    bottom: 90,
    cursor: 'pointer',
    position: 'fixed',
    right: 20,
    transitionDelay: '0s',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'linear',
  };

  const [isInCategoryBasedSearch, setIsInCategoryBasedSearch] =
    React.useState(false);

  /**
   * Handler function for search completion.
   */
  function handleCategoryBasedSearchEnd() {
    setIsInCategoryBasedSearch(false);
  }

  /**
   * Single-run effect to determine whether or not experiments are enabled via
   * ENV var value. If enabled, check for experiment cookie and value, matching
   * up with the appropriate values and state value to set.
   */
  React.useEffect(() => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const enableExperiments =
      process.env.REACT_APP_ENABLE_EXPERIMENTS &&
      process.env.REACT_APP_ENABLE_EXPERIMENTS.toString() === 'true';

    /**
     * Only consider setting category-based search if no query params are set,
     * as a way to ensure existing URLs with query params are honored and do not
     * put the user into the experimental search flow.
     */
    if (
      enableExperiments &&
      (!queryParams ||
        Object.keys(queryParams).length < 1 ||
        EXPERIMENT_URL_SEARCH_OVERRIDES.includes(window.location.search))
    ) {
      const experimentalCookieValue = getExperimentalCookieValue();
      const status =
        experimentalCookieValue ===
        EXPERIMENTS.values.categoryBasedSearch.cookieValue;
      setIsInCategoryBasedSearch(status);
      if (status) {
        callSegmentPage({
          name: 'LifeGroups Search',
          properties: {
            experimental_flag: getExperimentalCookieValue(),
            title: 'Category Based Search',
            url: window.location.href,
          },
        });
      }
    }
  }, []);

  return (
    <div className="search-page" data-testid="lg-search-page">
      {isInCategoryBasedSearch ? (
        <CategoryBasedSearch
          {...props}
          onSearchComplete={handleCategoryBasedSearchEnd}
        />
      ) : (
        <>
          <SearchContainer {...props} />
          <ListContainer {...props} />
          <MediaQuery query="(min-device-width: 768px)">
            <div
              className="lg-scroll-to-top"
              onClick={() => {
                const user = getUser();
                callSegmentTrack({
                  event: EVENTS.buttonAction,
                  properties: {
                    action: ACTIONS.clicked,
                    category: APP_NAME,
                    component: 'Search Page',
                    component_url: null,
                    label: 'Scroll to Top',
                    logged_in: !!user,
                    preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
                    referrer: document?.referrer || null,
                    title: document?.title || '',
                    url: window?.location?.href,
                    user_id:
                      user?.['https://www.life.church/rock_person_alias_id'],
                  },
                });
              }}
            >
              <ScrollToTop showUnder={200} style={scrollToTopStyle}>
                <img
                  alt="Scroll to top"
                  data-no-retina={true}
                  src={ScrollToTopImage}
                />
              </ScrollToTop>
            </div>
          </MediaQuery>
        </>
      )}
    </div>
  );
}

SearchPage.propTypes = {
  user: PropTypes.object,
};

export default SearchPage;
