// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';
import {
  callSegmentPage,
  getExperimentalCookieValue,
  injectScript,
} from 'helper';
import SearchPage from '../SearchPage';
import {
  fetchChurchOnlineLocations,
  fetchLifegroupData,
  fetchLocations,
  gotoState,
  removeCampus,
  setCampus,
  setChurchOnlineLocation,
  setMobileStatus,
  setParams,
  setUser,
} from '../../actions';
import { loadNewRelicBrowserMonitor } from '../../lib/new_relic_browser_monitor';
import { UISTATE_SEARCH_PAGE } from '../../types';

import '../../styles/main.scss';

function getCampus(campusList, campusSlug) {
  return campusList.find((o) => o.slug === campusSlug);
}

function getChurchOnlineLocation(
  churchOnlineLocations,
  churchOnlineLocationSlug,
) {
  return churchOnlineLocations.find((o) => o.slug === churchOnlineLocationSlug);
}

function handleChurchOnlineLocationParams(params, churchOnlineLocations) {
  // Set church online location to "all" if query parameter is not provided or value is not in the list.
  const defaultChurchOnlineLocation = getChurchOnlineLocation(
    churchOnlineLocations,
    'all',
  );
  if (!params.group_location) {
    return { ...params, churchOnlineLocation: defaultChurchOnlineLocation };
  }
  const churchOnlineLocationToSet = getChurchOnlineLocation(
    churchOnlineLocations,
    params.group_location.toLowerCase(),
  );
  return churchOnlineLocationToSet
    ? { ...params, churchOnlineLocation: churchOnlineLocationToSet }
    : { ...params, churchOnlineLocation: defaultChurchOnlineLocation };
}

function handleCampusAndChurchOnlineLocationParams(
  params,
  campusList,
  churchOnlineLocations,
) {
  // Set campus location to "all" if query parameter is not provided or value is not in the list.
  const defaultCampus = getCampus(campusList, 'all');
  const campuses = params.campus !== undefined ? params.campus.split(',') : '';
  if (!campuses) {
    return { ...params, campus: defaultCampus };
  }
  const campusToSet = campuses.map((cam) => {
    return getCampus(campusList, cam);
  });
  let newParams = campusToSet
    ? { ...params, campus: campusToSet }
    : { ...params, campus: defaultCampus };
  const intCampusIncluded = newParams.campus.every((campus) => {
    return campus?.slug === 'int';
  });
  if (intCampusIncluded) {
    newParams = handleChurchOnlineLocationParams(
      newParams,
      churchOnlineLocations,
    );
  }

  return newParams;
}

class App extends Component {
  constructor(props) {
    super(props);
    if (qs.parse(window.location.search, { ignoreQueryPrefix: true }).campus) {
      this.props.gotoState(UISTATE_SEARCH_PAGE);
    }
    // Fire off Segment call.
    callSegmentPage({
      name: 'LifeGroups Search',
      properties: {
        experimental_flag: getExperimentalCookieValue(),
        title: 'LifeGroups Search App',
        url: window.location.href,
      },
    });
  }

  UNSAFE_componentWillMount() {
    const isMobile = window.innerWidth <= 767;
    this.props.setMobileStatus(isMobile);
    this.props.fetchLocations().then(() => {
      this.props.fetchChurchOnlineLocations().then(() => {
        // Are there QS available?
        if (window.location.search) {
          let params = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
          });
          // Standardizing that all shortcodes are stored in lowercase
          // to ensure LifeGroups are loaded correctly
          if (Array.isArray(params?.campus)) {
            params.campus = params.campus[0].toLowerCase();
          } else if (params?.campus) {
            params.campus = params.campus.toLowerCase();
          } else {
            params.campus = 'all';
          }
          params = handleCampusAndChurchOnlineLocationParams(
            params,
            this.props.campusList,
            this.props.churchOnlineLocations,
          );
          if (params?.campus?.slug !== 'all') {
            this.props.removeCampus('all');
            params.campus.forEach((cam) => {
              this.props.setCampus(cam?.slug);
            });
          }
          this.props.setParams(params);
        }
        this.props.fetchLifegroupData();
      });
    });
    const newRelicMarkup =
      process.env.REACT_APP_NEW_RELIC_BROWSER_MONITOR_ENABLE &&
      process.env.REACT_APP_NEW_RELIC_BROWSER_MONITOR_ENABLE.toString() ===
        'true'
        ? `${loadNewRelicBrowserMonitor()}`
        : '';
    if (newRelicMarkup) {
      injectScript(
        {
          content: newRelicMarkup,
        },
        true,
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <div className="App">
        <SearchPage {...this.props} />
      </div>
    );
  }
}

App.propTypes = {
  campusList: PropTypes.array,
  churchOnlineLocations: PropTypes.array,
  fetchChurchOnlineLocations: PropTypes.func,
  fetchLifegroupData: PropTypes.func,
  fetchLocations: PropTypes.func,
  gotoState: PropTypes.func,
  removeCampus: PropTypes.func,
  setCampus: PropTypes.func,
  setChurchOnlineLocation: PropTypes.func,
  setMobileStatus: PropTypes.func,
  setParams: PropTypes.func,
  setUser: PropTypes.func,
  uiState: PropTypes.string,
  user: PropTypes.object,
};

function mapStateToProps({ core }) {
  return {
    campusList: core.campusList,
    churchOnlineLocations: core.churchOnlineLocations,
    uiState: core.uiState,
    user: core.user,
  };
}

const mapDispatchToProps = {
  fetchChurchOnlineLocations,
  fetchLifegroupData,
  fetchLocations,
  gotoState,
  removeCampus,
  setCampus,
  setChurchOnlineLocation,
  setMobileStatus,
  setParams,
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
