/**
 * @module CategoryButtons
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Button from '../Button';
import './CategoryBasedSearch.scss';

/**
 * Represents a view of a button lineup for a specific step in the category-based search flow.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onBackClick - Handler function for the back button click.
 * @param {Function} props.onNextClick - Handler function for the next button click.
 * @param {Function} props.onSkipClick - Handler function for the skip button click.
 * @param {number} props.stepNumber - The step number of the component to display.
 *
 * @returns {React.ReactElement} The CategoryButtons component.
 */
function CategoryButtons({
  onBackClick,
  onNextClick,
  onSkipClick,
  stepNumber,
}) {
  /**
   * Handler function for back button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleBackClick(event) {
    if (onBackClick && typeof onBackClick === 'function') {
      onBackClick(event);
    }
  }

  /**
   * Handler function for next button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleNextClick(event) {
    if (onNextClick && typeof onNextClick === 'function') {
      onNextClick(event);
    }
  }

  /**
   * Handler function for skip button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleSkipClick(event) {
    if (onSkipClick && typeof onSkipClick === 'function') {
      onSkipClick(event);
    }
  }

  if (stepNumber === 1) {
    return (
      <div className="category-button-container">
        <div className="group align-center">
          <Button
            btnText="Back"
            className="button-secondary button-medium icon-back"
            onClick={handleBackClick}
            type="button"
            value="Back"
          />
          <Button
            btnText="Skip"
            className="button-secondary button-medium icon-next"
            onClick={handleSkipClick}
            type="button"
            value="Skip"
          />
        </div>
      </div>
    );
  }

  if (stepNumber === 2) {
    return (
      <div className="category-button-container">
        <div className="group align-left">
          <Button
            btnText="Back"
            className="button-secondary button-medium icon-back"
            onClick={handleBackClick}
            type="button"
            value="Back"
          />
          <Button
            btnText="Skip"
            className="button-secondary button-medium icon-next"
            onClick={handleSkipClick}
            type="button"
            value="Skip"
          />
        </div>
        <div className="group align-right">
          <Button
            btnText="Next"
            className="button-primary button-medium"
            onClick={handleNextClick}
            type="button"
            value="next"
          />
        </div>
      </div>
    );
  }

  /**
   * Default to returning first-step markup.
   */
  return (
    <div className="category-button-container">
      <div className="group align-center">
        <Button
          btnText="Skip"
          className="button-secondary button-medium icon-next"
          onClick={handleSkipClick}
          type="button"
          value="Skip"
        />
      </div>
    </div>
  );
}

export { CategoryButtons };
